export default {
  navTab: {
    title: 'UNO ',
    tab1: 'Start',
    tab2: 'WHO WE ARE',
    tab3: 'WORK WITH US',
    tab4: 'CONTACT US'
  },
  homeTop: {
    h3Title: 'OMNICHANNEL CONTACT CENTER',
    h3Title1: 'Excellent Human Resources',
    desc: 'UNO OUTSOURCING COMPANY LIMITED is a human resources outsourcing company established in Lagos, Nigeria, with professional human resources outsourcing service capabilities, providing customers with professional human resources outsourcing planning and other services'
  },
  homeBottom: {
    title: 'What we offer',
    menu1: 'EFFICIENCY',
    menuDesc1: 'We operate with a management model that implements a continuous improvement process in your operation and our staff, supported by Work Force Management, Training, Business Intelligence and the Command Center.',
    menu2: 'PLATAFORMA MULTICANAL',
    menuDesc2: 'Gestionamos las interacciones con tus clientes en una plataforma multicanal que permite contactar de forma adecuada a tus clientes, por los medios que ellos buscan y se ajustan a sus necesidades.We manage interactions with your customers on a multi-channel platform that allows you to contact your customers appropriately, by the means they seek and adjust to their needs.',
    menu3: 'EXPERT STAFF',
    menuDesc3: 'We have the attraction, recruitment and training of qualified personnel, as well as an organizational culture program focused on the well-being of our collaborators to promote their professional development.',
    menu4: 'THE BEST SOLUTION OF',
    menu4br: 'CONTACT',
    menuDesc4: 'We analyze and optimize your processes to be more efficient and help make your company profitable'
  },
  bottom: {
    title: 'UNO OUTSOURCING',
    email: 'Finance.UNO@outlook.com',
    facebook: 'Facebook: Dino Outsource',
    about: 'ABOUT US',
    joinUs: 'WORK WITH US',
    contact: 'CONTACT US',
    privacyTitle: 'quick links',
    privacy: 'Notice of Privacy'
  },
  aboutUs: {
    title: 'Company Profile',
    p1: 'Based in Lagos, Nigeria, UNO OUTSOURCING COMPANY LIMITED is a trusted human resources outsourcing company with a proven track record of providing professional and reliable HR outsourcing services to clients across various industries. With a team of experienced and dedicated professionals, UNO OUTSOURCING COMPANY LIMITED offers a wide range of HR outsourcing solutions that include HR planning, talent acquisition, employee training and development, payroll management, and more.  ',
    p2: `At UNO OUTSOURCING COMPANY LIMITED, we understand that every client is unique, and we work closely with each one to develop customized HR outsourcing solutions that meet their specific needs. Our team of experts is committed to providing clients with top-quality services that are tailored to their business objectives. We also maintain a strong focus on compliance and regulatory requirements, ensuring that our clients remain in full compliance with all relevant laws and regulations. In addition to our core HR outsourcing services,  `,
    p4: `UNO OUTSOURCING COMPANY LIMITED also offers a range of value-added services that include HR consulting, employee benefits administration, and HR technology solutions. Our goal is to help clients achieve their business objectives by providing them with the best HR outsourcing services available. At UNO OUTSOURCING, we pride ourselves on our commitment to quality, professionalism, and integrity. We believe that our success is directly tied to the success of our clients, and we work hard to ensure that our clients receive the highest quality services and support. `,
    p5: 'At Blue Connect, employees have the opportunity to work with cutting-edge technology and be a part of a team that is dedicated to innovation. The company offers competitive salaries, flexible work arrangements, and a supportive work environment. '
  },
  joinUs: {
    title: 'Recruitment',
    subTitle: 'HumanResources Manager',
    descTitle: 'Job Description',
    descSubTitle: '【HumanResources Manager】',
    content1: 'Due to expansion, an IMPORTANT COMPANY IN THE FINTECH BRANCH is en-',
    content2: 'we invite you to join our great team of telephone advisors!!',
    section1Title: 'We offer:',
    section1Desc1: 'Base salary 50k ~ 55K NGN',
    section1Desc2: 'Incentives for productivity from 20k ~ 100K NGN',
    section1Desc3: 'Punctuality Bonus 5000 NGN',
    section1Desc4: 'Referral Bonus 350 NGN',
    section1Desc5: 'Benefits of law from the first day',
    section1Desc6: 'Paid Training',
    section1Desc7: 'Free Coffee Break Service',
    section1Desc8: 'Dining room with soda machine (Terrace with a view of Chapultepec Castle)',
    section1Desc9: 'Game Rooms',
    section1Desc10: 'Modern Installations',
    section2Title: 'Requirements:',
    section2Desc1: '18 to 45 years old',
    section2Desc2: 'Secondary completed',
    section2Desc3: 'Documentation in order',
    section2Desc4: "No experience needed, we'll train you!!",
    section2Desc5: 'Shift from 9 to 6 from Monday to Sunday with rest during the week',
    vitae: 'How to submit (apply) your Curriculum Vitae:',
    vitaeGray: 'Application via Email',
    vitaeOrange: '“Finance.UNO@outlook.com”'
  },
  contactUs: {
    title: 'CONTACT US',
    p1: 'Company Address：',
    p2: 'Cto. Interior Melchor Ocampo 469, Anzures, Miguel Hidalgo, 11590 Ciudad',
    p3: 'from Mexico, CDMX',
    p4: 'Email:',
    p5: 'Finance.UNO@outlook.com',
    p6: 'Facebook：',
    p7: 'Dino Outsource'
  }
}
